import { DEFAULT_THEME_CONFIG } from './constants.js';
import {
  type CustomThemeConfigContextProps,
  type CustomThemeConfigType,
} from './types.js';
import {
  removeStorageCustomization,
  setStorageCustomization,
} from './utilities/customThemeStorage.js';
import { getCustomizedThemeConfig } from './utilities/getCustomizedThemeConfig.js';
import { getStyleContents } from './utilities/getStyleContents.js';
import { getThemeChanges } from './utilities/isThemeEqual.js';
import {
  CreateThemeCustomizationInput,
  type CustomThemeConfigContextMutation,
} from '@/__generated__/relay/CustomThemeConfigContextMutation.graphql.js';
import { useToast } from '@/components/Toasts/hooks/useToast.js';
import { useTemplate } from '@/hooks/useTemplate.js';
import { useTheme } from '@/hooks/useTheme.js';
import { useUserProfile } from '@/hooks/useUserProfile.js';
import { logger } from '@/services/logger.js';
import {
  type TemplateNames,
  type ThemeNames,
} from '@/templates/templateTypes.js';
import { themeRegistry } from '@/templates/themeRegistry.js';
import { captureException } from '@sentry/react';
import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { graphql } from 'relay-runtime';
import {
  useMutation,
} from 'react-relay';

export const INJECTED_STYLE_TAG_ID = 'custom-theme-config';

const defaultFunction = () => {
  throw new Error('Must be called inside of a "CustomThemeConfigProvider"');
};

const log = logger.child({ namespace: 'CustomThemeConfigContext' });

export const CustomThemeConfigContext =
  createContext<CustomThemeConfigContextProps>({
    data: {
      customThemeConfig: null,
    },
    onCreateCustomization: defaultFunction,
    resetToDefaultTheme: defaultFunction,
    resetToPublishedTheme: defaultFunction,
    setCustomThemeConfig: defaultFunction,
    state: {
      isCreatingThemeCustomization: false,
      themeHasCustomChanges: false,
      themeHasUnpublishedChanges: false,
    },
  });

type CustomThemeConfigProviderProps = {
  readonly children: ReactNode;
};

export const CustomThemeConfigProvider = ({
  children,
}: CustomThemeConfigProviderProps): JSX.Element => {
  const userProfile = useUserProfile();
  const template = useTemplate();
  const theme = useTheme();
  const toast = useToast();

  // These are used as dependencies as need to remain as primitives
  // that can be compared via Object.is().
  const currentTemplate: TemplateNames = template.current;
  const currentTheme: ThemeNames = theme.current;
  const currentThemeId: string | null = theme.currentThemeId;

  const [themeHasCustomChanges, setThemeHasCustomChanges] =
    useState<boolean>(false);
  const [themeHasUnpublishedChanges, setThemeHasUnpublishedChanges] =
    useState<boolean>(false);

  // eslint-disable-next-line react/hook-use-state
  const [customThemeConfig, setCustomThemeConfigState] =
    useState<CustomThemeConfigType>(
      getCustomizedThemeConfig({
        currentThemeId,
        log,
        templateName: currentTemplate,
        themeName: currentTheme,
        userProfile,
      }),
    );

  // When the template or theme changes, we need to update the custom theme config
  useEffect(() => {
    log.debug('Updating custom theme config state directly');
    const newCustomizations = getCustomizedThemeConfig({
      currentThemeId,
      log,
      templateName: currentTemplate,
      themeName: currentTheme,
      userProfile,
    });

    setCustomThemeConfigState(newCustomizations);

    const { customChanges, unpublishedChanges } = getThemeChanges({
      customization: newCustomizations,
      templateName: currentTemplate,
      themeName: currentTheme,
      userProfile,
    });

    setThemeHasCustomChanges(customChanges);
    setThemeHasUnpublishedChanges(unpublishedChanges);
  }, [currentTemplate, currentTheme, currentThemeId, userProfile]);

  useLayoutEffect(() => {
    const parentClass = themeRegistry[currentTemplate][currentTheme].toString();
    const styleContents: string = getStyleContents(
      customThemeConfig,
      parentClass,
    );
    const styleElement = document.querySelector(`#${INJECTED_STYLE_TAG_ID}`);

    if (styleElement && styleElement instanceof HTMLStyleElement) {
      // Add CSS vars to the style element
      styleElement.innerText = styleContents;
    } else {
      const style = document.createElement('style');
      style.id = INJECTED_STYLE_TAG_ID;
      style.innerText = styleContents;
      document.head.appendChild(style);
    }
  }, [currentTemplate, currentTheme, customThemeConfig]);

  const resetToDefaultTheme = useCallback(() => {
    setCustomThemeConfigState(DEFAULT_THEME_CONFIG);

    removeStorageCustomization({
      templateName: currentTemplate,
      themeName: currentTheme,
      userId: userProfile?.id,
    });

    const { customChanges, unpublishedChanges } = getThemeChanges({
      customization: DEFAULT_THEME_CONFIG,
      templateName: currentTemplate,
      themeName: currentTheme,
      userProfile,
    });

    setThemeHasCustomChanges(customChanges);
    setThemeHasUnpublishedChanges(unpublishedChanges);
  }, [currentTemplate, currentTheme, userProfile]);

  const resetToPublishedTheme = useCallback(() => {
    removeStorageCustomization({
      templateName: currentTemplate,
      themeName: currentTheme,
      userId: userProfile?.id,
    });

    const customConfig = getCustomizedThemeConfig({
      currentThemeId,
      log,
      templateName: currentTemplate,
      themeName: currentTheme,
      userProfile,
    });

    setCustomThemeConfigState(customConfig);

    const { customChanges, unpublishedChanges } = getThemeChanges({
      customization: customConfig,
      templateName: currentTemplate,
      themeName: currentTheme,
      userProfile,
    });

    setThemeHasCustomChanges(customChanges);
    setThemeHasUnpublishedChanges(unpublishedChanges);
  }, [currentTemplate, currentTheme, currentThemeId, userProfile]);

  const setCustomThemeConfig = useCallback(
    (newThemeConfig: CustomThemeConfigType) => {
      setStorageCustomization({
        customization: newThemeConfig,
        templateName: currentTemplate,
        themeName: currentTheme,
        userId: userProfile?.id,
      });

      const { customChanges, unpublishedChanges } = getThemeChanges({
        customization: newThemeConfig,
        templateName: currentTemplate,
        themeName: currentTheme,
        userProfile,
      });

      setThemeHasCustomChanges(customChanges);
      setThemeHasUnpublishedChanges(unpublishedChanges);

      setCustomThemeConfigState(newThemeConfig);
    },
    [currentTemplate, currentTheme, userProfile],
  );

  const [createThemeCustomization, isCreatingThemeCustomization] =
    useMutation<CustomThemeConfigContextMutation>(graphql`
      mutation CustomThemeConfigContextMutation(
        $input: CreateThemeCustomizationInput!
      ) {
        createThemeCustomization(input: $input) {
          visitor {
            userAccount {
              profile {
                id
                displayUsername
                independentPortfolioTemplateTheme {
                  themeCustomization {
                    accentFontPack {
                      id
                      nid
                    }
                    backgroundColor
                    backgroundStyle {
                      id
                      nid
                    }
                    bodyFontPack {
                      id
                      nid
                    }
                    fontColorPrimary
                    fontColorSecondary
                    fontPack {
                      id
                      nid
                    }
                    headingFontPack {
                      id
                      nid
                    }

                    id
                    layout {
                      projectsVariant {
                        id
                        nid
                      }
                      servicesVariant {
                        id
                        nid
                      }
                    }
                    logoImage {
                      id
                      uid
                      sizeBytes
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);

  const onCreateCustomization = useCallback(
    (input: CreateThemeCustomizationInput) => {
      createThemeCustomization({
        onCompleted: (response) => {
          const { visitor } = response.createThemeCustomization;
          if (
            visitor?.userAccount?.profile.independentPortfolioTemplateTheme
              ?.themeCustomization
          ) {
            toast.success('Customization saved successfully!');

            setThemeHasCustomChanges(false);
            setThemeHasUnpublishedChanges(false);

            removeStorageCustomization({
              templateName: currentTemplate,
              themeName: currentTheme,
              userId: visitor?.userAccount.profile.id,
            });
          } else {
            log.debug(
              'Something went wrong and we did not get the expected response from the server.',
            );
          }
        },
        onError: (error) => {
          captureException(error);
          toast.error(
            'Oops! Something went wrong. Our team has been notified. Please try again.',
          );
        },
        variables: {
          input,
        },
      });
    },
    [createThemeCustomization, currentTemplate, currentTheme, toast],
  );

  const memoizedValue = useMemo<CustomThemeConfigContextProps>(
    () => ({
      data: {
        customThemeConfig,
      },
      onCreateCustomization,
      resetToDefaultTheme,
      resetToPublishedTheme,
      setCustomThemeConfig,
      state: {
        isCreatingThemeCustomization,
        themeHasCustomChanges,
        themeHasUnpublishedChanges,
      },
    }),
    [
      customThemeConfig,
      isCreatingThemeCustomization,
      onCreateCustomization,
      resetToDefaultTheme,
      resetToPublishedTheme,
      setCustomThemeConfig,
      themeHasCustomChanges,
      themeHasUnpublishedChanges,
    ],
  );

  return (
    <CustomThemeConfigContext.Provider value={memoizedValue}>
      {children}
    </CustomThemeConfigContext.Provider>
  );
};
