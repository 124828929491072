import { type useConfirmShortCodeMutation$data } from '@/__generated__/relay/useConfirmShortCodeMutation.graphql.js';
import { CodeInput } from '@/components/CodeInput/CodeInput.js';
import { Loader } from '@/components/Loader/Loader.js';
import { Modal } from '@/components/ModalV2/Modal.js';
import { useToast } from '@/components/Toasts/hooks/useToast.js';
import { useConfirmShortCode } from '@/hooks/useConfirmShortCode.js';
import { styled } from '@/stitches/index.js';
import { useCallback, useRef } from 'react';

type EmailShortCodeConfirmationContentProps = {
  readonly emailAddress: string;
  readonly isOpen: boolean;
  readonly onSuccess: VoidFunction;
};

const Container = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
});

const Heading = styled('div', {
  fontSize: '$23',
  fontWeight: 600,
  lineHeight: '$32',
});

const Paragraph = styled('div', {
  color: '$gray70',
  fontSize: '$14',
  fontWeight: 400,
  lineHeight: '$20',
  marginBottom: '$8',
  marginTop: '$8',
});

const HelperText = styled('div', {
  '& > button': {
    color: '$brandPurple',
    fontWeight: 600,
    marginLeft: '$4',
  },
  color: '$gray70',
  fontSize: '$14',
  fontWeight: 400,
  lineHeight: '$20',
  marginTop: '$8',
});

const ContraLoader = styled('div', {
  position: 'absolute',
  right: '$16',
  top: '$16',
});

export const ShortCodeConfirmationModalV2 = ({
  emailAddress,
  isOpen,
  onSuccess,
}: EmailShortCodeConfirmationContentProps) => {
  const toast = useToast();
  const [confirmShortCode, confirmShortCodeLoading] = useConfirmShortCode();
  const codeRef = useRef('3');

  const handleCompleted = useCallback(
    (
      {
        confirmShortCode: { visitor },
      }: useConfirmShortCodeMutation$data,
      callback: () => void,
    ) => {
      if (visitor) {
        toast.success('Code successfully verified', { role: 'alert' });
        onSuccess();
      } else {
        const errorMessage = 'Failed to confirm authentication code.';
        toast.error(errorMessage, { role: 'alert' });
        callback();
      }
    },
    [onSuccess, toast],
  );

  const handleCodeInputComplete = useCallback(
    (code: string, resetCode: VoidFunction) => {
      codeRef.current = code;

      confirmShortCode({
        input: {
          code,
          emailAddress,
        },
        onCompleted: (data) => {
          handleCompleted(data, resetCode);
        },
        onError: () => {
          toast.error(
            'Something went wrong while trying to confirm your code. Please try again.',
          );
          resetCode();
        },
      });
    },
    [confirmShortCode, emailAddress, handleCompleted, toast],
  );

  return (
    <Modal.Root open={isOpen}>
      <Modal.Content>
        <Modal.Body>
          <Container>
            {confirmShortCodeLoading ? (
              <ContraLoader>
                <Loader />
              </ContraLoader>
            ) : null}

            <Heading>Confirm your email to send request</Heading>
            <Paragraph>
              We sent a code to <strong>{emailAddress}</strong>
            </Paragraph>

            <CodeInput
              autoFocus
              onComplete={handleCodeInputComplete}
            />

            {codeRef.current ? (
              <HelperText>
                Didn&apos;t get your code?
                <button
                  onClick={() =>
                    handleCodeInputComplete(codeRef.current, () => {})
                  }
                  type="button"
                >
                  Send a new code
                </button>
              </HelperText>
            ) : null}
          </Container>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
