/**
 * @generated SignedSource<<8b4cc7cf21a7a1a1817b26b927e9a432>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmShortCodeResult = "EMAIL_ADDRESS_CHANGE_CONFIRMED" | "EMAIL_ADDRESS_CONFIRMED" | "LOGGED_IN" | "%future added value";
export type ConfirmShortCodeInput = {
  code: string;
  emailAddress: string;
  invitedToSignWorkspaceDocumentId?: string | null;
  networkInviteSlug?: string | null;
};
export type CurrentUserContextShortCodeMutation$variables = {
  input: ConfirmShortCodeInput;
};
export type CurrentUserContextShortCodeMutation$data = {
  readonly confirmShortCode: {
    readonly result: ConfirmShortCodeResult | null;
    readonly visitor: {
      readonly userAccount: {
        readonly profile: {
          readonly id: string;
          readonly visitorCanEdit: boolean;
        };
        readonly " $fragmentSpreads": FragmentRefs<"CurrentUserContext_userAccount">;
      } | null;
    } | null;
  };
};
export type CurrentUserContextShortCodeMutation = {
  response: CurrentUserContextShortCodeMutation$data;
  variables: CurrentUserContextShortCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "result",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitorCanEdit",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentUserContextShortCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmShortCodePayload",
        "kind": "LinkedField",
        "name": "confirmShortCode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Visitor",
            "kind": "LinkedField",
            "name": "visitor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccount",
                "kind": "LinkedField",
                "name": "userAccount",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CurrentUserContext_userAccount"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentUserContextShortCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmShortCodePayload",
        "kind": "LinkedField",
        "name": "confirmShortCode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Visitor",
            "kind": "LinkedField",
            "name": "visitor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccount",
                "kind": "LinkedField",
                "name": "userAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "analyticsUserId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onboardingType",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTestAccount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomDomainConnection",
                    "kind": "LinkedField",
                    "name": "customDomains",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomDomainEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomDomain",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "domain",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContraImage",
                        "kind": "LinkedField",
                        "name": "avatarImage",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uid",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasActivePortfolioSubscription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "topIndependentStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isFramerExpert",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "generalInquiryCtaIsVisible",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IndependentPortfolioTemplateTheme",
                        "kind": "LinkedField",
                        "name": "independentPortfolioTemplateTheme",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IndependentPortfolioTemplate",
                            "kind": "LinkedField",
                            "name": "template",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkPreferences",
                        "kind": "LinkedField",
                        "name": "workPreferences",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCurrentlyAvailable",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceivedTestimonialConnection",
                        "kind": "LinkedField",
                        "name": "receivedTestimonials",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "primaryProfessionalCategory",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayUsername",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userGroups",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userFeatures",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WaitListConnection",
                    "kind": "LinkedField",
                    "name": "waitLists",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WaitListEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WaitList",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "nid",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20825294cc0a2aa6056e28196b848240",
    "id": null,
    "metadata": {},
    "name": "CurrentUserContextShortCodeMutation",
    "operationKind": "mutation",
    "text": "mutation CurrentUserContextShortCodeMutation(\n  $input: ConfirmShortCodeInput!\n) {\n  confirmShortCode(input: $input) {\n    result\n    visitor {\n      userAccount {\n        ...CurrentUserContext_userAccount\n        profile {\n          id\n          visitorCanEdit\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment CurrentUserContext_userAccount on UserAccount {\n  analyticsUserId\n  onboardingType\n  id\n  isTestAccount\n  customDomains {\n    count\n    edges {\n      node {\n        domain\n        status\n        id\n      }\n    }\n  }\n  emailAddress\n  profile {\n    id\n    avatarImage {\n      uid\n      id\n    }\n    hasActivePortfolioSubscription\n    topIndependentStatus\n    isFramerExpert\n    generalInquiryCtaIsVisible\n    independentPortfolioTemplateTheme {\n      id\n      template {\n        displayName\n        id\n      }\n    }\n    workPreferences {\n      isCurrentlyAvailable\n    }\n    receivedTestimonials {\n      count\n    }\n    primaryProfessionalCategory\n    firstName\n    lastName\n    title\n    displayUsername\n  }\n  userGroups\n  userFeatures\n  waitLists {\n    edges {\n      node {\n        id\n        nid\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7396b63ae37ff363303751d6893184b2";

export default node;
